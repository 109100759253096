<template>
  <div>
  <el-container>
    <el-header>
      <HeaderBar></HeaderBar>
    </el-header>
    <el-aside>
      <SideBar></SideBar>
    </el-aside>
    <el-main>
    <el-container class="information">
      <el-descriptions class="margin-top" title="当前登录：" :column="1" border>       
      <el-descriptions-item>
        <template slot="label"><i class="el-icon-user"></i>部门</template>{{name}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"><i class="el-icon-mobile-phone"></i>账号</template>{{username}}
      </el-descriptions-item>
      </el-descriptions>      
    </el-container>
    </el-main>
     <el-button type="primary" size="big" class="exit" @click="exit()">退出</el-button>
  </el-container>
    </div>
</template>

<script>
import HeaderBar from '../components/HeaderBar.vue'
import SideBar from '../components/SideBar.vue'
export default {
    components:{
      HeaderBar,
      SideBar
    },
    data(){
      return{
        
      }
    },
    methods:{
      exit(){
         this.$message.success('退出成功，请重新登录');
         this.$router.push({ path: './LoginView', name: 'login' });
      }
    },
    computed: {
      username() {
        return this.$store.state.username; // 从store中获取用户名
      },
      name(){
        if(this.username == 'jishubu'){
          return '技术部'
        }
        else if(this.username == 'xuanchuanbu'){
          return '宣传部'
        }
        else if(this.username == 'yingyinbu'){
          return '影音部'
        }
        else if(this.username == 'caibianbu'){
          return '采编部'
        }
        else if(this.username == 'cehuabu'){
          return '策划部'
        }
        else if(this.username == 'waishibu'){
          return '外事部'
        }
        else {
          return '管理员'; 
        }
      }
    },
}
</script>

<style>
.information{
  position: relative;
  margin-left: 350px;
  margin-top:200px;
}
.exit{
  position:absolute;
  left:650px;
  top:500px;
}
</style>