<template>
<div>
  <el-container>
    <el-header>
      <HeaderBar></HeaderBar>
    </el-header>
    <el-aside>
      <SideBar></SideBar>
    </el-aside>
    <el-main>
      <el-table :data="tableData" style="width: 100%" max-height="680">
        <el-table-column fixed prop="id" label="序号" width="100"></el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="gender" label="性别" width="60"></el-table-column>
        <el-table-column prop="number" label="学号" width="120"></el-table-column>
        <el-table-column prop="major" label="专业班级" width="160"></el-table-column>    
        <el-table-column prop="first" label="第一志愿" width="120"></el-table-column>
        <el-table-column prop="second" label="第二志愿" width="120"></el-table-column>
        <el-table-column prop="third" label="第三志愿" width="120"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">个人介绍</el-button>
            <el-button @click="handleClickEva(scope.row)" type="text" size="small">评价</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <div>
    <el-pagination @current-change="handleCurrentChange" :page-size="9"
      layout="total, prev, pager, next, jumper" :total="totalRows"></el-pagination>
    </div>
  </el-container>
</div>
</template>

<script>
import axios from 'axios'
import SideBar from '../components/SideBar.vue'
import HeaderBar from '../components/HeaderBar.vue'
  export default {
    components:{
      SideBar,
      HeaderBar
    },
    methods: {
      deleteRow(index, rows) {
        rows.splice(index, 1);
      },

      handleClickEva(row) {
        this.selectedEva = row.evaluate; 
        this.$alert(this.selectedEva, '评价', {
          confirmButtonText: '确定',
      });
      },

      handleClick(row) {
        this.selectedIntro = row.introduction; 
        this.$alert(this.selectedIntro, '个人介绍', {
          confirmButtonText: '确定',
      });
      },

      handleCurrentChange(newPage) {
          this.currentPage = newPage;
        },
    },

    computed: {
        totalRows() {
            return this.tableData.length;
        },
        
        currentPageData() {
          const startIndex = (this.currentPage - 1) * this.pageSize;
          const endIndex = startIndex + this.pageSize;
          return this.tableData.slice(startIndex, endIndex);
        },
      },

    mounted() {
      axios.get("https://www.sutnws.top:8443/api/caibian").then(result => {
      const data = result.data.data;
      this.tableData = data.map(item => ({
        id: item.id, // 渲染到 "序号" 列的属性
        name: item.name, // 渲染到 "姓名" 列的属性
        gender: item.gender, 
        major:item.major,
        number:item.number,
        first:item.first,
        second:item.second,
        third:item.third,
        introduction:item.introduction,
        phone:item.phone,
        evaluate:item.evaluate
      }));
    })
    .catch(error => {
      console.error(error);
    });
    },
    data() {
      return {
        tableData:[],
        selectedIntro: null, 
        currentPage: 1, 
        pageSize: 9, 
      }
    }
  }
</script>
<style>
  
  .el-main{
    position:fixed;
    margin-left: 200px;
    margin-right:50px;
    margin-top:90px;
  }

  .el-pagination{
    position:absolute;
    bottom: 40px;
    left:580px;
   }

   html,body{
      margin:0;
   }
</style>