<template>
<div>
  <el-container>
    <el-header>
      <HeaderBar></HeaderBar>
    </el-header>
    <el-aside>
      <SideBar></SideBar>
    </el-aside>
    <el-main>
      <div style="display: flex; align-items: center;">
    <el-input v-model="searchInput" placeholder="请输入姓名" style="width:120px;margin-right:30px;margin-bottom:10px"></el-input>
    <el-button icon="el-icon-search" style="margin-right:50px;margin-bottom:10px" @click="searchData">搜索</el-button>
    <el-input v-model="searchInputid" placeholder="请输入学号" style="width:120px;margin-right:30px;margin-bottom:10px"></el-input>
    <el-button icon="el-icon-search" @click="searchIdData" style="margin-right:20px;margin-bottom:10px">搜索</el-button>
    <el-button @click="resetSearch" style="margin-bottom:10px">重置</el-button>
    <span style="margin-left: 400px;">当前登录：{{ username }}</span>
     </div> 
      <el-table :data="currentPageData" style="width: 100%" max-height="680">
        <el-table-column fixed prop="id" label="序号" width="100"></el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="gender" label="性别" width="60"></el-table-column>
        <el-table-column prop="number" label="学号" width="120"></el-table-column>
        <el-table-column prop="major" label="专业班级" width="160"></el-table-column>    
        <el-table-column prop="first" label="第一志愿" width="120"></el-table-column>
        <el-table-column prop="second" label="第二志愿" width="120"></el-table-column>
        <el-table-column prop="third" label="第三志愿" width="120"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">个人介绍</el-button>
            <el-button @click.native.prevent="enroll(scope.$index,scope.row)" type="text" size="small">录取</el-button>
            <el-button @click.native.prevent="deleteRow(scope.$index, scope.row)" type="text" size="small">待选</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <div>
    <el-pagination @current-change="handleCurrentChange" :page-size="9"
      layout="total, prev, pager, next, jumper" :total="totalRows"></el-pagination>
    </div>
  </el-container>
</div>
</template>

<script>
import axios from 'axios'
import SideBar from '../components/SideBar.vue'
import HeaderBar from '../components/HeaderBar.vue'
  export default {
    components:{
      SideBar,
      HeaderBar
    },
    methods: {

     /*
        待选方法，删除某一行的数据，并将删除的数据传递到筛选表中
     */

    deleteRow(index, row) {
       console.log('当前行的数据：', row);

      this.$prompt('请输入原因', '你确认将该人员放入筛选池吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(({value}) => {
        const id = row.id; // 假设数据行中有唯一标识id
        const evaluate = value;
          // 在row对象上添加evaluate字段
           row.evaluate = value;
           console.log("",evaluate);
          
         /*
            判断现在是哪个部门的候选表,执行删除操作
         */ 
         if(this.$store.state.username === 'jishubu'){
            axios.delete(`https://www.sutnws.top:8443/api/candidate_js/${id}`)
          .then(() => {
          // 删除成功，更新表格数据，从表格中删除对应的数据行
          this.tableData.splice(index, 1);
          // 将删除的数据传递到另一个表中
          const deletedData = { ...row}; // 存储删除的数据到对象中
          console.log("",deletedData),
          axios.post('https://www.sutnws.top:8443/api/send_sx', deletedData)
            .then(() => {
              this.$message({
                type: 'success',
                message: '删除成功，并成功传递删除的数据到筛选表中!'
              });
            })
            .catch(error => {
              console.error(error);
              this.$message({
                type: 'error',
                message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
              });
            });
                })
                .catch(error => {
                  console.error(error);
                  this.$message({
                    type: 'error',
                    message: '删除失败，请稍后再试'
                  });
                });
         }
          else if(this.$store.state.username === 'yingyinbu'){
              axios.delete(`https://www.sutnws.top:8443/api/candidate_yy/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_sx', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '删除成功，并成功传递删除的数据到筛选表中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                }
          else if(this.$store.state.username === 'xuanchuanbu'){
              axios.delete(`https://www.sutnws.top:8443/api/candidate_xc/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https:/www.sutnws.top:8443/api/send_sx', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '删除成功，并成功传递删除的数据到筛选表中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                }
          else if(this.$store.state.username === 'cehuabu'){
              axios.delete(`https://www.sutnws.top:8443/api/candidate_ch/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_sx', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '删除成功，并成功传递删除的数据到筛选表中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                }
          else if(this.$store.state.username === 'caibianbu'){
              axios.delete(`https://www.sutnws.top:8443/api/candidate_cb/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_sx', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '删除成功，并成功传递删除的数据到筛选表中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                }
          else if(this.$store.state.username === 'waishibu'){
              axios.delete(`https://www.sutnws.top:8443/api/candidate_ws/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_sx', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '删除成功，并成功传递删除的数据到筛选表中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                }
          else if(this.$store.state.username === 'admin'){
            delete
              axios.delete(`https://www.sutnws.top:8443/api/candidate_js/${id}`)
              .then(() => {
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_sx', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '删除成功，并成功传递删除的数据到筛选表中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                }
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });
            });
          },

      //重置搜索框和表格
      resetSearch() {
        this.tableData = this.originalData; // 将tableData恢复为原始数据
        this.searchInput = ''; // 清空搜索输入框
        this.searchInputid = '';
      },

      /*
        搜索数据方法
      */

      searchData() {
        const keyword = this.searchInput.trim().toLowerCase(); // 获取搜索关键字，并将其转换为小写
        const originalData = [...this.tableData]; // 备份原始数据

        this.tableData = this.tableData.filter(item => {
          return item.name.toLowerCase().includes(keyword); // 通过姓名搜索
        });

          // 检查搜索结果是否为空
          if (this.tableData.length === 0) {
            this.$message({
              type: 'info',
              message: '搜索结果为空'
            });
            // 恢复原始数据
            this.tableData = originalData;
          } else {
            this.$message({
              type: 'success',
              message: '查询成功！点击刷新返回页面'
            });
          }
        },

      searchIdData() {
        const keyword = this.searchInputid.trim().toLowerCase(); // 获取搜索关键字，并将其转换为小写
        const originalData = [...this.tableData]; // 备份原始数据

        this.tableData = this.tableData.filter(item => {
          return item.number.toLowerCase().includes(keyword); // 通过姓名搜索
        });

          // 检查搜索结果是否为空
          if (this.tableData.length === 0) {
            this.$message({
              type: 'info',
              message: '搜索结果为空'
            });
            // 恢复原始数据
            this.tableData = originalData;
          } else {
            this.$message({
              type: 'success',
              message: '查询成功！点击刷新返回页面'
            });
          }
      },

      handleCurrentChange(newPage) {
          this.currentPage = newPage;
     },
      /*
        录取方法
      */

      enroll(index, row) {
        this.$prompt('请输入评语', '你确认录取该人员吗', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(({value}) => {
          const id = row.id; 
          const evaluate = value;
            row.evaluate = value;
            console.log("",evaluate);       
            /*
                根据用户名判断应该删除和插入到哪个表
            */
            if(this.$store.state.username === 'jishubu'){
               axios.delete(`https://www.sutnws.top:8443/api/candidate_js/${id}`)
              .then(() => {
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_js', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '录取成功，人员已经被添加到技术部中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                  }
              else if(this.$store.state.username === 'yingyinbu'){
                  axios.delete(`https://www.sutnws.top:8443/api/candidate_yy/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_yy', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '录取成功，人员已经被添加到影音部中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除失败，请稍后再试'
                        });
                      });
                }
              else if(this.$store.state.username === 'caibianbu'){
                axios.delete(`https://www.sutnws.top:8443/api/candidate_cb/${id}`)
                .then(() => {        
                  this.tableData.splice(index, 1);      
                  const deletedData = { ...row}; 
                  console.log("",deletedData),
                  axios.post('https://www.sutnws.top:8443/api/send_cb', deletedData)
                    .then(() => {
                      this.$message({
                        type: 'success',
                        message: '录取成功，人员已经被添加到采编部中!'
                      });
                    })
                    .catch(error => {
                      console.error(error);
                      this.$message({
                        type: 'error',
                        message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                      });
                    });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '删除失败，请稍后再试'
                          });
                        });
                }
              else if(this.$store.state.username === 'xuanchuanbu'){
                axios.delete(`https://www.sutnws.top:8443/api/candidate_xc/${id}`)
                .then(() => {        
                  this.tableData.splice(index, 1);      
                  const deletedData = { ...row}; 
                  console.log("",deletedData),
                  axios.post('https://www.sutnws.top:8443/api/send_xc', deletedData)
                    .then(() => {
                      this.$message({
                        type: 'success',
                        message: '录取成功，人员已经被添加到宣传部中!'
                      });
                    })
                    .catch(error => {
                      console.error(error);
                      this.$message({
                        type: 'error',
                        message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                      });
                    });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '删除失败，请稍后再试'
                          });
                        });
                }
              else if(this.$store.state.username === 'cehuabu'){
                  axios.delete(`https://www.sutnws.top:8443/api/candidate_ch/${id}`)
                .then(() => {        
                  this.tableData.splice(index, 1);      
                  const deletedData = { ...row}; 
                  console.log("",deletedData),
                  axios.post('https//www.sutnws.top:8443/api/send_ch', deletedData)
                    .then(() => {
                      this.$message({
                        type: 'success',
                        message: '录取成功，人员已经被添加到策划部中!'
                      });
                    })
                    .catch(error => {
                      console.error(error);
                      this.$message({
                        type: 'error',
                        message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                      });
                    });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '删除失败，请稍后再试'
                          });
                        });
                }
              else if(this.$store.state.username === 'waishibu'){
                  axios.delete(`https://www.sutnws.top:8443/api/candidate_ws/${id}`)
                  .then(() => {        
                    this.tableData.splice(index, 1);      
                    const deletedData = { ...row}; 
                    console.log("",deletedData),
                    axios.post('https://www.sutnws.top:8443/api/send_ws', deletedData)
                      .then(() => {
                        this.$message({
                          type: 'success',
                          message: '录取成功，人员已经被添加到外事部中!'
                        });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                        });
                      });
                          })
                          .catch(error => {
                            console.error(error);
                            this.$message({
                              type: 'error',
                              message: '删除失败，请稍后再试'
                            });
                          });
                }
              else if(this.$store.state.username === 'admin'){
                 axios.delete(`https://www.sutnws.top:8443/api/candidate_ch/${id}`)
                    .then(() => {
                      this.tableData.splice(index, 1);      
                      const deletedData = { ...row}; 
                      console.log("",deletedData),
                      axios.post('https://www.sutnws.top:8443/api/send_ch', deletedData)
                        .then(() => {
                          this.$message({
                            type: 'success',
                            message: '录取成功，人员已经被添加到技术部中!'
                          });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '删除成功，但传递数据到另一个表时出现错误，请稍后再试'
                          });
                        });
                            })
                            .catch(error => {
                              console.error(error);
                              this.$message({
                                type: 'error',
                                message: '删除失败，请稍后再试'
                              });
                            });
               }
                  }).catch(() => {
                    this.$message({
                      type: 'info',
                      message: '已取消删除'
                    });
                  });
                },

      /*
        点击打开个人介绍
      */
      handleClick(row) {
        this.selectedIntro = row.introduction; // 将个人介绍数据存储到selectedIntro中
        this.$alert(this.selectedIntro, '个人介绍', {
          confirmButtonText: '确定',
      });
    },
    },

    //计算属性
    computed: {
      username() {
        return this.$store.state.username;  // 从store中获取用户名
      },
      totalRows() {
          return this.tableData.length;
    },
    // 计算当前页的数据
    currentPageData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.tableData.slice(startIndex, endIndex);
    },
    },

    /*
      根据登录的用户名查询候选报名表
    */

    mounted() {
      if(this.$store.state.username === 'jishubu'){
        axios.get("https://www.sutnws.top:8443/api/candidate_js").then(result => {
        const data = result.data.data;
        this.tableData = data.map(item => ({
          id: item.id, // 渲染到 "序号" 列的属性
          name: item.name, 
          gender: item.gender, 
          major:item.major,
          number:item.number,
          first:item.first,
          second:item.second,
          third:item.third,
          introduction:item.introduction,
          phone:item.phone
        }));
          this.originalData = this.tableData
      })
      .catch(error => {
        console.error(error);
      });
      }
      else if(this.$store.state.username === 'xuanchuanbu'){
        axios.get("https://www.sutnws.top:8443/api/candidate_xc").then(result => {
        const data = result.data.data;
        this.tableData = data.map(item => ({
          id: item.id, 
          name: item.name, 
          gender: item.gender, 
          major:item.major,
          number:item.number,
          first:item.first,
          second:item.second,
          third:item.third,
          introduction:item.introduction,
          phone:item.phone
        }));
        this.originalData = this.tableData
      })
      .catch(error => {
        console.error(error);
      });
      }
       else if(this.$store.state.username === 'yingyinbu'){
        axios.get("https://www.sutnws.top:8443/api/candidate_yy").then(result => {
        const data = result.data.data;
        this.tableData = data.map(item => ({
          id: item.id, 
          name: item.name, 
          gender: item.gender, 
          major:item.major,
          number:item.number,
          first:item.first,
          second:item.second,
          third:item.third,
          introduction:item.introduction,
          phone:item.phone
        }));
        this.originalData = this.tableData
      })
      .catch(error => {
        console.error(error);
      });
      }
       else if(this.$store.state.username === 'caibianbu'){
        axios.get("https://www.sutnws.top:8443/api/candidate_cb").then(result => {
        const data = result.data.data;
        this.tableData = data.map(item => ({
          id: item.id, 
          name: item.name, 
          gender: item.gender, 
          major:item.major,
          number:item.number,
          first:item.first,
          second:item.second,
          third:item.third,
          introduction:item.introduction,
          phone:item.phone
        }));
        this.originalData = this.tableData
      })
      .catch(error => {
        console.error(error);
      });
      }
       else if(this.$store.state.username === 'cehuabu'){
        axios.get("https://www.sutnws.top:8443/api/candidate_ch").then(result => {
        const data = result.data.data;
        this.tableData = data.map(item => ({
          id: item.id, 
          name: item.name, 
          gender: item.gender, 
          major:item.major,
          number:item.number,
          first:item.first,
          second:item.second,
          third:item.third,
          introduction:item.introduction,
          phone:item.phone
        }));
        this.originalData = this.tableData
      })
      .catch(error => {
        console.error(error);
      });
      }
       else if(this.$store.state.username === 'waishibu'){
        axios.get("https://www.sutnws.top:8443/api/candidate_ws").then(result => {
        const data = result.data.data;
        this.tableData = data.map(item => ({
          id: item.id, 
          name: item.name, 
          gender: item.gender, 
          major:item.major,
          number:item.number,
          first:item.first,
          second:item.second,
          third:item.third,
          introduction:item.introduction,
          phone:item.phone
        }));
        this.originalData = this.tableData
      })
      .catch(error => {
        console.error(error);
      });
      }
       else if(this.$store.state.username === 'admin'){
        axios.get("https://www.sutnws.top:8443/api/candidate_ch").then(result => {
        const data = result.data.data;
        this.tableData = data.map(item => ({
          id: item.id, // 渲染到 "序号" 列的属性
          name: item.name, // 渲染到 "姓名" 列的属性
          gender: item.gender, 
          major:item.major,
          number:item.number,
          first:item.first,
          second:item.second,
          third:item.third,
          introduction:item.introduction,
          phone:item.phone
        }));
        this.originalData = this.tableData
      })
      .catch(error => {
        console.error(error);
      });
      }

    },
    data() {
      return {
        tableData:[],
        selectedIntro: null, // 存储选中行的个人介绍数据
        searchInput: '', // 用于保存搜索关键字
        searchInputid:'',
        input: '', // 输入框的值
        inputid:'',
        originalData: [], // 存储原始数据
        currentPage: 1, // 当前页数
        pageSize: 9, // 每页行数
      }
    }
  }
</script>
<style>
  
  .el-main{
    position:fixed;
    margin-left: 200px;
    margin-right:50px;
    margin-top:90px;
  }

   html,body{
      margin:0;
   }
 
   .el-pagination{
    position:absolute;
    bottom: 40px;
    left:580px;
   }
</style>