<template>
<div>
  <el-container>
    <el-header>
      <HeaderBar></HeaderBar>
    </el-header>
    <el-aside>
      <SideBar></SideBar>
    </el-aside>
    <el-main>
      <el-table :data="tableData" style="width: 100%" max-height="680">
        <el-table-column fixed prop="id" label="序号" width="100"></el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="gender" label="性别" width="60"></el-table-column>
        <el-table-column prop="number" label="学号" width="120"></el-table-column>
        <el-table-column prop="major" label="专业班级" width="160"></el-table-column>    
        <el-table-column prop="first" label="第一志愿" width="120"></el-table-column>
        <el-table-column prop="second" label="第二志愿" width="120"></el-table-column>
        <el-table-column prop="third" label="第三志愿" width="120"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">个人介绍</el-button>
            <el-button @click="handleClickEva(scope.row)" type="text" size="small">评价</el-button>
            <el-button @click.native.prevent="enroll(scope.$index,scope.row)" type="text" size="small">录取</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <div>
    <el-pagination @current-change="handleCurrentChange" :page-size="9"
      layout="total, prev, pager, next, jumper" :total="totalRows"></el-pagination>
    </div>
  </el-container>
</div>
</template>

<script>
import axios from 'axios'
import SideBar from '../components/SideBar.vue'
import HeaderBar from '../components/HeaderBar.vue'
  export default {
    components:{
      SideBar,
      HeaderBar
    },
    methods: {
      /*
        点击打开个人介绍
      */
      handleClick(row) {
        this.selectedIntro = row.introduction; // 将个人介绍数据存储到selectedIntro中
        this.$alert(this.selectedIntro, '个人介绍', {
          confirmButtonText: '确定',
      });
      },
      /*
        点击打开评价
      */
      handleClickEva(row) {
        this.selectedEva = row.evaluate; 
        this.$alert(this.selectedEva, '评价', {
          confirmButtonText: '确定',
      });
      },

      handleCurrentChange(newPage) {
          this.currentPage = newPage;
        },

      enroll(index, row) {
        this.$confirm('你确认录取该人员吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const id = row.id;  
            /*
                根据用户名判断应该删除和插入到哪个表
            */
            if(this.$store.state.username === 'jishubu'){
               axios.delete(`https://www.sutnws.top:8443/api/shaixuan/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_js', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '录取成功，人员已经被添加到技术部中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '录取失败，请稍后再试'
                        });
                      });
                  }
              else if(this.$store.state.username === 'yingyinbu'){
                axios.delete(`https://www.sutnws.top:8443/api/shaixuan/${id}`)
              .then(() => {        
                this.tableData.splice(index, 1);      
                const deletedData = { ...row}; 
                console.log("",deletedData),
                axios.post('https://www.sutnws.top:8443/api/send_yy', deletedData)
                  .then(() => {
                    this.$message({
                      type: 'success',
                      message: '录取成功，人员已经被添加到影音部中!'
                    });
                  })
                  .catch(error => {
                    console.error(error);
                    this.$message({
                      type: 'error',
                      message: '传递数据到另一个表时出现错误，请稍后再试'
                    });
                  });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '录取失败，请稍后再试'
                        });
                      });
                }
              else if(this.$store.state.username === 'caibianbu'){
                axios.delete(`https://www.sutnws.top:8443/api/shaixuan/${id}`)
                .then(() => {        
                  this.tableData.splice(index, 1);      
                  const deletedData = { ...row}; 
                  console.log("",deletedData),
                  axios.post('https://www.sutnws.top:8443/api/send_cb', deletedData)
                    .then(() => {
                      this.$message({
                        type: 'success',
                        message: '录取成功，人员已经被添加到采编部中!'
                      });
                    })
                    .catch(error => {
                      console.error(error);
                      this.$message({
                        type: 'error',
                        message: '数据到另一个表时出现错误，请稍后再试'
                      });
                    });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '删除失败，请稍后再试'
                          });
                        });
                }
              else if(this.$store.state.username === 'xuanchuanbu'){
                axios.delete(`https://www.sutnws.top:8443/api/shaixuan/${id}`)
                .then(() => {        
                  this.tableData.splice(index, 1);      
                  const deletedData = { ...row}; 
                  console.log("",deletedData),
                  axios.post('https://www.sutnws.top:8443/api/send_xc', deletedData)
                    .then(() => {
                      this.$message({
                        type: 'success',
                        message: '录取成功，人员已经被添加到宣传部中!'
                      });
                    })
                    .catch(error => {
                      console.error(error);
                      this.$message({
                        type: 'error',
                        message: '传递数据到另一个表时出现错误，请稍后再试'
                      });
                    });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '录取失败，请稍后再试'
                          });
                        });
                }
              else if(this.$store.state.username === 'cehuabu'){
                  axios.delete(`https://www.sutnws.top:8443/api/shaixuan/${id}`)
                .then(() => {        
                  this.tableData.splice(index, 1);      
                  const deletedData = { ...row}; 
                  console.log("",deletedData),
                  axios.post('https://www.sutnws.top:8443/api/send_ch', deletedData)
                    .then(() => {
                      this.$message({
                        type: 'success',
                        message: '录取成功，人员已经被添加到策划部中!'
                      });
                    })
                    .catch(error => {
                      console.error(error);
                      this.$message({
                        type: 'error',
                        message: '数据传到另一个表时出现错误，请稍后再试'
                      });
                    });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '录取失败，请稍后再试'
                          });
                        });
                }
              else if(this.$store.state.username === 'waishibu'){
                  axios.delete(`https://www.sutnws.top:8443/api/shaixuan/${id}`)
                  .then(() => {        
                    this.tableData.splice(index, 1);      
                    const deletedData = { ...row}; 
                    console.log("",deletedData),
                    axios.post('https://www.sutnws.top:8443/api/send_ws', deletedData)
                      .then(() => {
                        this.$message({
                          type: 'success',
                          message: '录取成功，人员已经被添加到外事部中!'
                        });
                      })
                      .catch(error => {
                        console.error(error);
                        this.$message({
                          type: 'error',
                          message: '传递数据到另一个表时出现错误，请稍后再试'
                        });
                      });
                          })
                          .catch(error => {
                            console.error(error);
                            this.$message({
                              type: 'error',
                              message: '录取失败，请稍后再试'
                            });
                          });
                }
              else if(this.$store.state.username === 'admin'){
                      axios.delete(`https://www.sutnws.top:8443/api/shaixuan/${id}`)
                    .then(() => {        
                      this.tableData.splice(index, 1);      
                      const deletedData = { ...row}; 
                      console.log("",deletedData),
                      axios.post('https://www.sutnws.top:8443/api/send_js', deletedData)
                        .then(() => {
                          this.$message({
                            type: 'success',
                            message: '录取成功，人员已经被添加到技术部中!'
                          });
                        })
                        .catch(error => {
                          console.error(error);
                          this.$message({
                            type: 'error',
                            message: '传递数据到另一个表时出现错误，请稍后再试'
                          });
                        });
                            })
                            .catch(error => {
                              console.error(error);
                              this.$message({
                                type: 'error',
                                message: '录取失败，请稍后再试'
                              });
                            });
               }
                  }).catch(() => {
                    this.$message({
                      type: 'info',
                      message: '已取消录取'
                    });
                  });
                },
          },

        computed: {
          totalRows() {
            return this.tableData.length;
        },
        // 计算当前页的数据
        currentPageData() {
          const startIndex = (this.currentPage - 1) * this.pageSize;
          const endIndex = startIndex + this.pageSize;
          return this.tableData.slice(startIndex, endIndex);
        },
        },
        mounted() {
          axios.get("https://www.sutnws.top:8443/api/shaixuan").then(result => {
          const data = result.data.data;
          this.tableData = data.map(item => ({
            id: item.id, // 渲染到 "序号" 列的属性
            name: item.name, // 渲染到 "姓名" 列的属性
            gender: item.gender, 
            major:item.major,
            number:item.number,
            first:item.first,
            second:item.second,
            third:item.third,
            introduction:item.introduction,
            phone:item.phone,
            evaluate:item.evaluate
          }));
        })
        .catch(error => {
          console.error(error);
        });
        },
        data() {
          return {
            tableData:[],
            currentPage: 1, 
            pageSize: 9, 
          }
        }
      }
</script>
<style>
  
  .el-main{
    position:fixed;
    margin-left: 200px;
    margin-right:50px;
    margin-top:90px;
  }

  .el-pagination{
    position:absolute;
    bottom: 40px;
    left:580px;
   }

   html,body{
      margin:0;
   }
</style>