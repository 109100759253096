<template>
  <el-container>
    <el-header class="elheader">
      <h1>校网管报名后台管理系统</h1>
    </el-header>
  </el-container>
</template>

<script>
export default {

}
</script>

<style>
  .el-header {
    background-color: white;
    color: #333;
    line-height: 60px;
    padding: 0 20px;
    text-align: center;
  }
  .el-header{
    background-color: #23262E;
  }
  h1{
    color:white;
    font-size: 20px;
    margin-top:2px;
  }
</style>