<template>
 <div class="content">
    <el-form ref="form" class="login_container" :model="login" status-icon :rules="rules" label-width="70px">
        <!-- h3要放在里面:只能有一个根,且title也是表单的一部分 -->
        <h3 class="login_title">用户登录</h3>
        <!-- prop对应rules里的键 -->
        <el-form-item label="用户名" prop="username">
            <el-input v-model="login.username" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="密码" prop="password">
            <el-input type="password"  show-password v-model="login.password" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item style="width:100%">
            <el-button @click="Login()" type="primary" style="margin-left:30px;margin-top:10px">登录</el-button>
        </el-form-item>
    </el-form>
    <!-- <p class="beian">ICP主体备案号：辽ICP备2023007438号</p> -->
    <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">ICP主体备案号：辽ICP备2023007438号</a>
 </div>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      login: {
        username: '',
        password: ''
      },
      loginAttempts: 0,
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, message: '密码长度至少为5个字符', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    Login() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.login.username.trim() === '' || this.login.password.trim() === '') {
            this.$message.error('用户名和密码不能为空');
          } else if (this.login.username.trim().length < 5 || this.login.password.trim().length < 5) {
            this.$message.error('用户名和密码长度必须至少为5个字符');
          } else {
            if (this.loginAttempts >= 5) {
               this.$message.error('登录失败次数过多，请稍后再试');
               return;
             }
            this.axios.post('https://www.sutnws.top:8443/api/login', this.login).then((resp) => {
              let data = resp.data;
              if (data.code === 1) {
                this.$message.success('登录成功，欢迎进入后台管理页面');
                 //提交mutation来更新store中的用户名
                const username = this.login.username;
                this.$store.commit('setUsername', username);      //触发执行 mutations 的方法
                console.log('登录成功，用户名为：', username);
                this.$router.push({ path: './HomeView', name: 'home' });         
              } else {
                this.$message.error('登录失败，用户名或密码错误');
                this.loginAttempts++; 
                return false;
              }
            })

          }
        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
.login_container {
    width: 350px;
    border: 1px solid #eaeaea;

    // 居中
    margin: 180px auto;
    padding: 35px 35px 15px 35px;

    // 让padding在width里面
    box-sizing: border-box;

    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 0 25px #cac6c6;

    .login_title {
        color: #505458;
        text-align: center;
        margin-bottom: 40px;
    }

    .el-input {
        width: 198px;
    }   
}
  .beian{
      position: absolute;
      margin-left: 570px;
      margin-right: 100px;
      bottom: 40px; /* 调整垂直位置 */
      text-align: center;
      color:#fff;
      text-decoration:none
    }
  .content{
      position: absolute;
      background: url("../assets/background.jpg");
      background-size:cover ;
      width:100%;
      height: 100%;
  }
</style>
