import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
 
export default new Vuex.Store({

  //公共state对象，数据，相当于data
  state: {
    username:''
  },

  //唯一取值的方法，计算属性,将state中的数据进行一些处理，然后返回处理后的结果
  getters: {
    getUsername(state) {
      return state.username;
    },
  },

  //里面定义方法，唯一可以修改state值的,通过执行 mutations，可以更改 store 中的数据，并保持状态的同步。
  mutations: {
    setUsername(state, username) {
      state.username = username; // 更新用户名的状态
    }
  },

  // 操作异步操作mutation
  actions: {
    
  },

  //将store分解为多个独立的模块，每个模块负责管理一部分数据。
  modules: {
    
  },
})
