<template>
  <div class="maincontent">
  <!-- 侧边栏 -->
  <el-container style="height: 830px">
    <el-menu default-active="$route.path" class="el-menu-vertical-demo"
      @open="handleOpen" @close="handleClose"
        router
      background-color="#393D49"
      text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item index="/home">
          <i class="el-icon-menu"></i>
          <span slot="title">部门候选人员</span>
        </el-menu-item>
         <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-user"></i>
            <span>部门人员管理</span>
          </template>
          <el-menu-item-group>
            <template slot="title">部门人数查看</template>
            <el-menu-item index="/TechnologyDep">技术部</el-menu-item>
          </el-menu-item-group>
          <el-menu-item index="/VideoDep">影音部</el-menu-item>
          <el-menu-item index="/GatherDep">采编部</el-menu-item>
          <el-menu-item index="/PublicizeDep">宣传部</el-menu-item>
          <el-menu-item index="/PlanDep">策划部</el-menu-item>
          <el-menu-item index="/ForeignDep">外事部</el-menu-item>
        </el-submenu>
        <el-menu-item index="/ShaiXuan">
          <i class="el-icon-s-order"></i>
          <span slot="title">筛选池</span>
        </el-menu-item>
        <el-menu-item index="/ProfileMes">
          <i class="el-icon-setting"></i>
          <span slot="title">账号管理</span>
        </el-menu-item>
      </el-menu>
</el-container>
  </div>
</template>

<script>
  export default {
    methods:{
      handleOpen(key,keyPath){
        console.log(key,keyPath);
      },
      handleClose(key,keyPath){
        console.log(key,keyPath);
      },
    }
     
  };
</script>

<style>
  .maintitle{
    font-size:26px;
  }

  .el-aside {
    color: #333;
  }

  .el-menu-item-group{
    background-color: #23262E;
  }

  .elmenu{
    background-color:#393D49;
  }
  
  .el-pagination{
    text-align: center;
  }
  
</style>