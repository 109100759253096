import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SideBar from '../components/SideBar'
import TechnologyDep from '../views/TechnologyDep.vue'
import VideoDep from '../views/VideoDep'
import PublicizeDep from '../views/PublicizeDep'
import GatherDep from '../views/GatherDep'
import PlanDep from '../views/PlanDep'
import ForeignDep from '../views/ForeignDep'
import ProfileMes from '../views/ProfileMes'
import ShaiXuan from '../views/ShaiXuan'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path:'/home',
    name:'home',
    component:HomeView
  },
  {
    path:'/SideBar',
    name:'Sidebar',
    component:SideBar,
  },
  {
    path:'/TechnologyDep',
    name:'TechnologyDep',
    component:TechnologyDep
  },
  {
    path:'/VideoDep',
    name:'VideoDep',
    component:VideoDep
  },
  {
    path:'/PlanDep',
    name:'PlanDep',
    component:PlanDep
  },
  {
    path:'/PublicizeDep',
    name:'PublicizeDep',
    component:PublicizeDep
  },
  {
    path:'/GatherDep',
    name:'GatherDep',
    component:GatherDep
  },
  {
    path:'/ForeignDep',
    name:'ForeignDep',
    component:ForeignDep
  },
  {
    path:'/ProfileMes',
    name:'ProfileMes',
    component:ProfileMes
  },
  {
    path:'/ShaiXuan',
    name:'ShaiXuan',
    component:ShaiXuan
  }
]

const router = new VueRouter({
  routes
})


export default router
